import { CustomModalErrors } from "@components/CustomModalErrors"
import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import ImageMobile from "@images/background-admin-1024.png"
import ImageDesktop from "@images/background-admin-1366.png"
import SigninFormSite from "@modules/Auth/Site/components/SigninFormSite"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import translate from "@src/i18n"
import { Card } from "antd"
import cx from "classnames"
import { navigate } from "gatsby"
import _map from "lodash/map"
import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useMedia } from "react-use-media"
import SvgCloseModal from "../../components/Icons/components/CloseModal"

// import Logo from "@images/logo.png"
import NewLogo from "../../images/content/logo-dark.svg"
import styles from "./style.module.less"

const imagesLogin = [
  {
    id: 1,
    mobile: ImageMobile,
    desktop: ImageDesktop,
  },
]

const Login = () => {
  const [isClient, setIsClient] = useState(false)
  const [areaCode, setAreaCode] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [errors, setErrors] = useState([])

  const isMobile = useMedia({ minWidth: 150, maxWidth: 767 })

  const { authSite } = useStores()

  const {
    login,
    loginErrorMessage,
    clearLoginErrorMessage,
    getAllCountriesAreaCode,
    user,
  } = authSite

  useEffect(() => {
    setIsClient(true)
    retrieveAllAreaCode()
  }, [])

  useEffect(() => {
    const adaptedErrors = errorsAdapter(loginErrorMessage)
    setErrors(adaptedErrors)
  }, [loginErrorMessage])

  useEffect(() => {
    if (user) {
      window.location.href = "/feed"
    }
  }, [user])

  const retrieveAllAreaCode = async () => {
    const response = await getAllCountriesAreaCode()

    const arrayDataAreaCode = _map(response, (item) => ({
      id: item.id,
      code: `${item.areaCode}`,
    }))

    arrayDataAreaCode.sort((a, b) => a.code.localeCompare(b.code))

    setAreaCode(arrayDataAreaCode)
  }

  const handleLogin = async (props) => {
    const { codeNumber, phone, password } = props

    setLoading(true)

    await login({
      phoneNumber: `${codeNumber}${phone.replace(/[^0-9]+/g, "")}`,
      password: password,
    })

    setLoading(false)
  }

  const handleClose = () => {
    setVisible(false)
  }

  if (!isClient) return null

  const classesImg = cx({
    [styles.backgroundMobile]: isMobile,
    [styles.backgroundDesktop]: !isMobile,
  })

  return (
    <TranslateWrapper>
      <Helmet>
        <title>Login - Anonimatta</title>
      </Helmet>
      <div>
        {_map(imagesLogin, (item) => {
          const srcImage = isMobile ? item.mobile : item.desktop

          return (
            <div key={item.id} className={styles.loginContainer}>
              <img src={srcImage} className={classesImg} />
              <div className={styles.cardPosition}>
                <Card className="card-login flex justify-center">
                  <button
                    title="Close (Esc)"
                    onClick={() => navigate("/")}
                    type="button"
                    className={styles.buttonClose}
                  >
                    <SvgCloseModal />
                  </button>

                  <div className={cx("tc", styles.newLogoStyle)}>
                    <img src={NewLogo} className={styles.logo} />

                    <div className={styles.descriptionPadding}>
                      <label
                        className={cx(
                          "f14 normal lh-copy scorpion-main",
                          styles.subtitle
                        )}
                      >
                        {translate("descriptionLogin")}
                      </label>
                    </div>
                  </div>

                  <div>
                    <SigninFormSite
                      visible={visible}
                      loading={loading}
                      areaCode={areaCode}
                      handleLogin={handleLogin}
                      handleClose={handleClose}
                      setVisible={setVisible}
                    />
                  </div>
                </Card>
              </div>
            </div>
          )
        })}

        <CustomModalErrors
          errors={errors}
          setErrors={(errors) => {
            setErrors(errors)
            clearLoginErrorMessage()
          }}
        />
      </div>
    </TranslateWrapper>
  )
}

export default observer(Login)
