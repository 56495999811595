import { LoadingOutlined } from "@ant-design/icons"
import { Icon } from "@components/Icons"
import MyAccountModal from "@components/myAccountModal"
import { SuccessModal } from "@components/SuccessModal"
import ForgotPassword from "@modules/MyAccount/ForgotPassword"
import translate from "@src/i18n"
import { maskPhone } from "@utils/strings"
import { Button, Form, Input, Select } from "antd"
import cx from "classnames"
import { navigate } from "gatsby"
import _map from "lodash/map"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import styles from "../../style.module.less"

const FormLoginSite = ({
  areaCode,
  loading,
  handleLogin,
  errorRecover,
  visible,
  setVisible,
  handleClose,
  loadingRecover,
}) => {
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState(null)
  const [successMessage, setSuccessMessage] = useState("")

  const [form] = Form.useForm()

  const intl = useIntl()

  useEffect(() => {
    form.setFieldsValue({ phone: maskPhone(phone) })
  }, [phone])

  const handleChangePhoneNumber = (e) => {
    let value = e.target.value
    setPhone(value)
  }

  const handleClickForgotPassword = (e) => {
    e.preventDefault()
    setVisible(true)
  }

  const handleCloseSuccessModal = () => {
    setSuccessMessage("")
  }

  const handleSubmit = (props) => {
    handleLogin(props)
  }

  const validatePhoneNumber = () => ({
    validator(rule, value) {
      if (value) {
        value = maskPhone(value)

        const regexPhoneNumber = new RegExp(/^(\(\d{2}\)) (\d{5})-(\d{4})$/)
        // const regexPhoneNumber = new RegExp(/^(\(\d{2}\)) (\d{4,5})-(\d{4})$/) // para wpp com número fixo

        if (!regexPhoneNumber.test(value)) {
          setPhoneError(intl.formatMessage({ id: "errorPhoneNumber" }))
          return Promise.reject(intl.formatMessage({ id: "errorPhoneNumber" }))
        }
      }

      setPhoneError(null)
      return Promise.resolve()
    },
  })

  const validateMessages = {
    required: intl.formatMessage({ id: "requiredInput" }),
    string: {
      min: intl.formatMessage({ id: "messageErrorPassword" }),
    },
    types: {
      email: intl.formatMessage({ id: "emailInputMessage" }),
    },
  }

  return (
    <Form
      form={form}
      className="form-login"
      layout="vertical"
      validateMessages={validateMessages}
      onFinish={handleSubmit}
      initialValues={{
        codeNumber: "55",
        phone: "",
        password: "",
      }}
    >
      <div className={styles.labelNicknameLogin}>
        <label className={cx("scorpion-main", styles.customLabelLogin)}>
          {translate("phoneNumber")}
        </label>
      </div>

      <div className="flex flex-row">
        <div className="w-30">
          <Form.Item name="codeNumber" rules={[{ required: true }]}>
            <Select
              allowClear={false}
              defaultValue={"+55"}
              showSearch
              className="select-login"
              optionFilterProp="children"
              disabled={loading}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {_map(areaCode, (item) => (
                <Select.Option key={item.id} value={item.code}>
                  {`+${item.code}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="ml8 w-100">
          <Form.Item
            name="phone"
            rules={[{ type: "string", required: true }, validatePhoneNumber]}
            validateStatus={"error"}
            help={phoneError}
          >
            <Input
              value={phone}
              onChange={handleChangePhoneNumber}
              className="input-login"
              disabled={loading}
              maxLength={15}
            />
          </Form.Item>
        </div>
      </div>

      <Form.Item
        name="password"
        label={translate("password")}
        className={styles.bottomDynamic}
        validateStatus={"error"}
        validateFirst
        rules={[
          {
            type: "string",
            required: true,
            min: 6,
          },
          {
            pattern:
              /^(?=.*[0-9])(?=.*[a-zA-Z])([A-Za-z\d$@$!%*#=+()^?&\-_'"`~ç{}[\]\\/,.]+)$/,
            message: intl.formatMessage({ id: "passwordError" }),
          },
        ]}
      >
        <Input.Password
          className="input-login"
          iconRender={(visible) =>
            visible ? <Icon name="EyeOpen" /> : <Icon name="EyeBlocked" />
          }
          disabled={loading}
        />
      </Form.Item>

      <div className={cx("pb24", styles.recoverLink)}>
        <a
          href="#"
          className={cx("lh-copy", styles.recover)}
          onClick={handleClickForgotPassword}
        >
          {translate("recoverPassword")}
        </a>
      </div>

      <div className="tc">
        <Button
          type="primary"
          htmlType="submit"
          className="button-log"
          ghost="true"
        >
          {loading ? (
            <p>
              <LoadingOutlined className={styles.loadingMore} />
            </p>
          ) : (
            translate("log")
          )}
        </Button>
      </div>

      <Form.Item className="tc">
        <Button
          type="primary"
          onClick={() => navigate("/cadastro-assinante")}
          className="tc button-create-account"
        >
          {translate("createAccount")}
        </Button>
      </Form.Item>

      {visible && (
        <MyAccountModal
          visible={visible}
          handleClose={handleClose}
          loadingRecover={loadingRecover}
          errorRecover={errorRecover}
          className={styles.modal}
        >
          <ForgotPassword
            areaCode={areaCode}
            setVisible={setVisible}
            setSuccessMessage={setSuccessMessage}
          />
        </MyAccountModal>
      )}

      {successMessage ? (
        <SuccessModal
          visible={!!successMessage}
          description={successMessage}
          handleClose={handleCloseSuccessModal}
          buttonText={intl.formatMessage({ id: "continue" })}
        />
      ) : null}
    </Form>
  )
}

export default FormLoginSite
